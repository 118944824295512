import Web3 from "web3";
// import Moralis from "moralis";
import {
    getAppInfo,
    getTargetChainId,
    getContracts,
    setTestNet
} from "./Config";
import {
    checkAndAddChain,
    requestAccount,
} from "./MateMaskUtils";

//登录获取钱包地址
export const requestMoralis = async () => {
    let user = Moralis.User.current();
    if (!user) {
        user = await Moralis.authenticate({
            signingMessage: "Log in BlackWar using Moralis",
        });
    }
    return user.get("ethAddress");
}

//推出登录
export const logOutMoralis = async () => {
    await Moralis.User.logOut();
}

export const loginMoralis = async () => {
    await checkAndAddChain();
    const walletAccount = await requestAccount();
    await Moralis.enableWeb3();
    const moralisAccount = await requestMoralis();
    if (walletAccount !== moralisAccount) {
        await logOutMoralis();
        return requestMoralis();
    } else {
        return walletAccount;
    }
};

//解析错误消息
export const formatError = err => {
    if (typeof err === "string") {
        return err;
    }

    if (err && err.data && err.data.message) {
        return err.data.message;
    }

    if (err && err.message) {
        return err.message;
    }

    return err;
}

//初始化
export const initMoralis = (bTestNet) => {
    setTestNet(bTestNet);
    const appInfo = getAppInfo();
    Moralis.start({
        ...appInfo
    });
}

//只读接口
export const callContract = async ({ contractAddress, functionName, abi, params }) => {
    const options = {
        contractAddress,
        functionName,
        abi: [abi],
        params,
    };
    return await Moralis.executeFunction(options);
}

//写接口
export const sendContract = async ({ contractAddress, functionName, abi, params, hashCallback, value }) => {
    const options = {
        contractAddress,
        functionName,
        abi: [abi],
        params,
        msgValue: value,
    };
    const transaction = await Moralis.executeFunction(options);
    if (hashCallback) {
        hashCallback(transaction.hash);
    }
    const res = await transaction.wait();
    return res;
}

//获取BNB
export const getNativeBalance = async (account) => {
    const options = {
        chain: getTargetChainId(),
        address: account
    };
    const { balance } = await Moralis.Web3API.account.getNativeBalance(options);
    return balance;
}

//获取token余额
export const getTokenBalance = async (account, token) => {
    const abi = {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            }
        ],
        "name": "balanceOf",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    };
    const result = await callContract({
        contractAddress: token,
        functionName: "balanceOf",
        abi,
        params: {
            account,
        }
    });
    return result.toString();
}

//erc20-approve
export const erc20Approve = async (contractAddress, spender, amount, hashCallback) => {
    const abi = {
        "inputs": [
            {
                "internalType": "address",
                "name": "spender",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "approve",
        "outputs": [
            {
                "internalType": "bool",
                "name": "",
                "type": "bool"
            }
        ],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    return await sendContract({
        contractAddress,
        functionName: "approve",
        abi,
        hashCallback,
        params: {
            spender,
            amount
        }
    });
}

//erc777-send
export const erc777Send = async (contractAddress, recipient, amount, data, hashCallback) => {
    const abi = {
        "inputs": [
            {
                "internalType": "address",
                "name": "recipient",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "send",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    return await sendContract({
        contractAddress,
        functionName: "send",
        abi,
        hashCallback,
        params: {
            recipient,
            amount,
            data
        }
    });
}

export const erc721Send = async (contractAddress, from, to, tokenId, data, hashCallback) => {
    const abi = {
        "inputs": [
            {
                "internalType": "address",
                "name": "from",
                "type": "address"
            },
            {
                "internalType": "address",
                "name": "to",
                "type": "address"
            },
            {
                "internalType": "uint256",
                "name": "tokenId",
                "type": "uint256"
            },
            {
                "internalType": "bytes",
                "name": "data",
                "type": "bytes"
            }
        ],
        "name": "safeTransferFrom",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    return await sendContract({
        contractAddress,
        functionName: "safeTransferFrom",
        abi,
        hashCallback,
        params: {
            from,
            to,
            tokenId,
            data
        }
    });
}

export const getWeb3 = async () => {
    if (window.myWeb3Js) {
        return window.myWeb3Js;
    } else {
        const web3Provider = await Moralis.enableWeb3();
        window.myWeb3Js = new Web3(web3Provider);
        return window.myWeb3Js;
    }
}

//get exchange config
export const getExchangeConfig = async (account, tokenType) => {
    const abi = {
        "inputs": [
            {
                "internalType": "address",
                "name": "account",
                "type": "address"
            },
            {
                "internalType": "enum TokenType",
                "name": "tokenType",
                "type": "uint8"
            }
        ],
        "name": "getConfig",
        "outputs": [
            {
                "internalType": "uint256",
                "name": "poolWat",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "userWat",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "poolToken",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "userToken",
                "type": "uint256"
            },
            {
                "internalType": "uint256",
                "name": "price",
                "type": "uint256"
            }
        ],
        "stateMutability": "view",
        "type": "function"
    };
    return await callContract({
        contractAddress: getContracts().exchangePool,
        functionName: "getConfig",
        abi,
        params: {
            account,
            tokenType
        }
    });
}

//充值wat
export const rechargeWatWithBnb = async (bnbAmount, hashCallback) => {
    const abi = {
        "inputs": [],
        "name": "rechargeWithBNB",
        "outputs": [],
        "stateMutability": "payable",
        "type": "function"
    }

    return await sendContract({
        contractAddress: getContracts().exchangePool,
        functionName: "rechargeWithBNB",
        abi,
        hashCallback,
        value: bnbAmount,
    });
}

export const rechargeWatWithUSD = async (tokenType, amount, hashCallback) => {
    const abi = {
        "inputs": [
            {
                "internalType": "enum TokenType",
                "name": "tokenType",
                "type": "uint8"
            },
            {
                "internalType": "uint256",
                "name": "amount",
                "type": "uint256"
            }
        ],
        "name": "rechargeWithUSD",
        "outputs": [],
        "stateMutability": "nonpayable",
        "type": "function"
    }
    return await sendContract({
        contractAddress: getContracts().exchangePool,
        functionName: "rechargeWithUSD",
        abi,
        hashCallback,
        params: {
            tokenType,
            amount
        }
    });
}

