<template>
    <div class="template blindbox" v-if="MoralisUser">
        <ul>
            <li v-for="(item,inx) in blindBoxList" :class="item.level">
                <img v-if="item.level=='SR'" src="https://cdn.blockwar.io/web_assets/img/blindbox/box_sr.png" alt="">
                <img v-if="item.level=='R'" src="https://cdn.blockwar.io/web_assets/img/blindbox/box_r.png" alt="">
                <img v-if="item.level=='N'" src="https://cdn.blockwar.io/web_assets/img/blindbox/box_n.png" alt="">
                <h2 class="mortina">RSRITY <span>{{ item.level }}</span></h2>
                <dl>
                    <dd v-for="i in item.price">
                        <div class="info">
                            <div>
                                <img v-if="i.name=='BNB'" src="https://cdn.blockwar.io/web_assets/img/ico_price_bnb.png" alt="">
                                <img v-if="i.name=='USDT'" src="https://cdn.blockwar.io/web_assets/img/ico_price_usdt.png" alt="">
                                {{ i.name }}
                            </div>
                            <span v-if="i.name=='BNB'">{{ (i.showValue / 1).toFixed(3) }}</span>
                            <span v-if="i.name=='USDT'">{{ (i.showValue / 1).toFixed(3) }}</span>
                        </div>
                        <ui_btn_1 v-if="i.name=='BNB'" @click="openByBnb(i,item.level)" :width="'2.4rem'" :height="'0.58rem'" :fontsize="'0.24rem'">OPEN</ui_btn_1>
                        <ui_btn_1 v-if="i.name=='USDT'" @click="openByUSDT(i,item.level)" :width="'2.4rem'" :height="'0.58rem'" :fontsize="'0.24rem'">OPEN</ui_btn_1>
                    </dd>
                </dl>
                <p>Inventory <span>{{ inventory }}</span></p>
            </li>
        </ul>
    </div>
    <div v-else>
        Please log in with your wallet！
    </div>
</template>
<script setup>
import ui_btn_1 from '@/components/ui/ui_btn_1.vue';
import { onMounted,computed,ref, watch } from 'vue';
import { useStore } from 'vuex';
import common from '@/utils/common';
import { sendContract, callContract, erc20Approve } from "../../utils/block_chain/MoralisUtils";

import BigNumber from 'bignumber.js';
let store = useStore();
//定链id
const BSC_MAIN = "0x38";
const BSC_TEST = "0x61";

//定义合约地址，测试网，主网地址待定
const WCT_MINTER_ASSRESS = "0xB93DcF8f066ca75349C858b2A16d99df101f93B1";

const USDT_ADDRESS = "0x337610d27c682E347C9cD60BD4b3b107C9d34dDd";

const RequestTaskEventTopic = "0xf21bb0a053cfdeeccc68535351b5af89bfbce41622a15d15a7b630e2a1ce034b";
const CreateTaskEventTopic = "0x0219371fd51cf8c19f3fce54fcea641d9822d94d9ade421f6cf0db76c54bdc28";

//定义盲盒等级
const BlindboxLevel = {
    N: 0,
    R: 1,
    SR: 2.
}
let inventory = ref('-');
const blindBoxList = ref([
    {
        level: 'N',
        price: [
            {
                name:'BNB',
                value:'-',
                showValue:'-',
            },
            {
                name:'USDT',
                value:'-',
                showValue:'-',
            },
        ],
        inventory: inventory.value
    },
    {
        level: 'R',
        price: [
            {
                name:'BNB',
                value:'-',
                showValue:'-',
            },
            {
                name:'USDT',
                value:'-',
                showValue:'-',
            },
        ],
        inventory: inventory.value
    },
    {
        level: 'SR',
        price: [
            {
                name:'BNB',
                value:'-',
                showValue:'-',
            },
            {
                name:'USDT',
                value:'-',
                showValue:'-',
            },
        ],
        inventory: inventory.value
    },
])

//查询盲盒abi
const QueryPriceAbi = {
    "inputs": [],
    "name": "getPrices",
    "outputs": [
        {
            "internalType": "uint256[3]",
            "name": "bnbPrices",
            "type": "uint256[3]"
        },
        {
            "internalType": "uint256[3]",
            "name": "usdtPrices",
            "type": "uint256[3]"
        }
    ],
    "stateMutability": "view",
    "type": "function"
};

//查询库存abi
const QueryStockAbi = {
    "inputs": [],
    "name": "getStock",
    "outputs": [
        {
            "internalType": "uint256",
            "name": "",
            "type": "uint256"
        }
    ],
    "stateMutability": "view",
    "type": "function"
};

//开盲盒abi
const OpenByBnbAbi = {
    "inputs": [
        {
            "internalType": "enum WctLevel",
            "name": "level",
            "type": "uint8"
        },
        {
            "internalType": "uint64",
            "name": "referrerUid",
            "type": "uint64"
        }
    ],
    "name": "openByBnb",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
};

//开盲盒abi
const OpenByUSDTAbi = {
    "inputs": [
        {
            "internalType": "enum WctLevel",
            "name": "level",
            "type": "uint8"
        },
        {
            "internalType": "uint64",
            "name": "referrerUid",
            "type": "uint64"
        }
    ],
    "name": "openByUsdt",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
};

//生成结果event abi
const CreateResEventAbi = {
    "anonymous": false,
    "inputs": [
        {
            "indexed": true,
            "internalType": "uint256",
            "name": "taskId",
            "type": "uint256"
        },
        {
            "indexed": true,
            "internalType": "uint256",
            "name": "tokenId",
            "type": "uint256"
        },
        {
            "indexed": true,
            "internalType": "address",
            "name": "to",
            "type": "address"
        },
        {
            "indexed": false,
            "internalType": "enum WctLevel",
            "name": "level",
            "type": "uint8"
        },
        {
            "indexed": false,
            "internalType": "enum WctCategory",
            "name": "category",
            "type": "uint8"
        }
    ],
    "name": "RandomEvent",
    "type": "event"
};

//定义工人类别
const WorkerCategory = {
    Miner: 1,
    Farmer: 2,
    Faller: 3,
}
//查询盲盒库存
const queryStock = async () => {
    try {
        const res = await callContract({
            contractAddress: WCT_MINTER_ASSRESS,
            functionName: QueryStockAbi.name,
            abi: QueryStockAbi
        });
        // setStock(res.toNumber());
        console.log('queryStock',res,res.toNumber())
        inventory.value = res.toNumber();
    } catch (error) {
        console.warn("error zzz", error);
    }
};

//查询盲盒价格
let BnbPrices,UsdtPrices;
const queryPrices = async () => {
    try {
        const { bnbPrices, usdtPrices } = await callContract({
            contractAddress: WCT_MINTER_ASSRESS,
            functionName: QueryPriceAbi.name,
            abi: QueryPriceAbi
        });
        BnbPrices = bnbPrices;
        UsdtPrices = usdtPrices;
        console.log('bnbPrices, usdtPrices',bnbPrices, usdtPrices);
        for(let i=0;i<bnbPrices.length;i++){
            // blindBoxList.value[i].price[0].value = common.formatCount18Float(Number(BigInt(bnbPrices[i])));
            blindBoxList.value[i].price[0].value =bnbPrices[i];
            blindBoxList.value[i].price[0].showValue = Moralis.Units.FromWei(bnbPrices[i]);
        }
        for(let i=0;i<usdtPrices.length;i++){
            // blindBoxList.value[i].price[1].value = common.formatCount18Float(Number(BigInt(usdtPrices[i])));
            blindBoxList.value[i].price[1].value = usdtPrices[i];
            blindBoxList.value[i].price[1].showValue = Moralis.Units.FromWei(usdtPrices[i]);
        }
        console.log('blindBoxList.value',blindBoxList.value);
    } catch (error) {
        console.warn("error", error);
    }
};

//查询开盲盒结果
const queryTaskInfoByEvent = async (fromBlock, taskId) => {
    try {
        const options = {
            chain: (location.hostname=='locahost'||location.hostname.indexOf('wargamedev')>-1)?BSC_TEST:BSC_MAIN, //主网用BSC_MAIN
            address: WCT_MINTER_ASSRESS,
            topic: CreateTaskEventTopic,
            limit: "100",
            abi: CreateResEventAbi,
            from_block: fromBlock,
        };
        const res = await Moralis.Web3API.native.getContractEvents(options);
        console.log("events", res);

        //如果没有如果查询到，等待3秒再查，如果一直查不到提示错误
        res.result.forEach(element => {
            if (element.data.taskId == taskId) {
                //解析结果
                if (element.data.category == WorkerCategory.Miner) {
                    console.log("开出矿工");
                } else if (element.data.category == WorkerCategory.Farmer) {
                    console.log("开出农民");
                } else if (element.data.category == WorkerCategory.Faller) {
                    console.log("开出伐木工");
                }
            }
        });

    } catch (error) {
        console.warn("error", error);
    }
};

const parseTaskId = (events) => {
    for (let index = 0; index < events.length; index++) {
        const element = events[index];
        if (element.address === WCT_MINTER_ASSRESS && element.topics[0] === RequestTaskEventTopic) {
            return parseInt(element.topics[1], 16);
        }
    }

    return 0;
};

//大整数转16进制字符串
function dec2hex(str){ // .toString(16) only works up to 2^53
    var dec = str.toString().split(''), sum = [], hex = [], i, s
    while(dec.length){
        s = 1 * dec.shift()
        for(i = 0; s || i < sum.length; i++){
            s += (sum[i] || 0) * 10
            sum[i] = s % 16
            s = (s - sum[i]) / 16
        }
    }
    while(sum.length){
        hex.push(sum.pop().toString(16))
    }
    return hex.join('')
}

//开盲盒
const openByBnb = async (i,level) => {
    try {
        // let msgValue = parseInt('0x'+dec2hex(i.value),16);
        // console.log(i.value,msgValue,msgValue.toString())
        let msgValue = i.value;
        console.log( msgValue);
        console.log(msgValue,msgValue.toString(),typeof(msgValue.toString()));
        const res = await sendContract({
            contractAddress: WCT_MINTER_ASSRESS,
            functionName: OpenByBnbAbi.name,
            abi: OpenByBnbAbi,
            hashCallback: (hash) => {
                console.log("交易哈希", hash);
            },
            params: {
                level,
                referrerUid: 134
            },
            value: msgValue.toString()
        });

        console.log("res", res);

        //开盲盒是异步的，此时只是表示创建开盲盒任务成功，并不表示开盲盒成功
        //获取任务id，3秒后根据taskId查询盲盒的结果
        const blockNumber = res.blockNumber;
        const taskId = parseTaskId(res.events);

        console.log("blockNumber", blockNumber);
        console.log("taskId", taskId);

        //三秒后查询开盲盒结果
        setTimeout(() => {
            queryTaskInfoByEvent(blockNumber, taskId)
        }, 10000);

    } catch (error) {
        console.warn("error", error);
    }
};

//开盲盒
const openByUSDT = async (i,lv) => {
    try {
        //执行开盲盒交易之前，先授予合约转账权限
        await erc20Approve(
            USDT_ADDRESS,
            WCT_MINTER_ASSRESS,
            i.value,
            (hash) => {
                console.log("approve transcation hash:", hash);
            }
        );

        //执行开盲盒交易
        const res = await sendContract({
            contractAddress: WCT_MINTER_ASSRESS,
            functionName: OpenByUSDTAbi.name,
            abi: OpenByUSDTAbi,
            hashCallback: (hash) => {
                console.log("交易哈希", hash);
            },
            params: {
                level:lv,
                referrerUid: 134
            }
        });

        console.log("res", res);

        //开盲盒是异步的，此时只是表示创建开盲盒任务成功，并不表示开盲盒成功
        //获取任务id，3秒后根据taskId查询盲盒的结果
        const blockNumber = res.blockNumber;
        const taskId = parseTaskId(res.events);

        console.log("blockNumber", blockNumber);
        console.log("taskId", taskId);

        //三秒后查询开盲盒结果
        setTimeout(() => {
            //23831139
            queryTaskInfoByEvent(blockNumber, taskId)
        }, 10000);

    } catch (error) {
        console.warn("error", error);
    }
};


var lang = computed(() => {
    return store.state.lang;
});
var langTxt = store.state.text;
let uid = computed(() => {
    return store.state.uid;
});

let MoralisUser = computed(()=>{
    return store.state.MoralisUser;
}); // 云用户。有了云用户才能去获取UID和token。
watch(MoralisUser,(newVal,oldVal)=>{
    console.log('MoralisUser',newVal,oldVal);
    queryStock();
    queryPrices();
})
onMounted(()=>{
    console.log('MoralisUser.value',MoralisUser.value);
    if (MoralisUser.value) {
        console.log('MoralisUser.value',MoralisUser.value);
        queryStock();
        queryPrices();
    }
    else{
        common.showLogin();
    }
});
</script>
<style lang="scss" scope>
.template.blindbox {
    width: 19.2rem;
    margin: 0 auto;
    height: 10.16rem;
    background: url("https://cdn.blockwar.io/web_assets/img/blindbox/bg.png") no-repeat;
    background-size: 100% auto;
    display: flex;
    justify-content: center;
    align-items: center;
    ul{
        display: flex;
        li{
            width: 3.4rem;
            height: 7rem;
            border-radius: 0.2rem;
            border: 1px solid #3C3C64;
            background: rgba(40, 40, 80, 0.8) url('https://cdn.blockwar.io/web_assets/img/blindbox/item_bg.png') no-repeat center 0.8rem;
            background-size: 3.6rem 2.4rem;
            margin: 0 0.6rem;
            cursor: pointer;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color:#fff;
            font-size: 0.24rem;
            img{
                width: 3rem;
            }
            h2{
                width: 100%;
                text-align: right;
                margin-top: -0.2rem;
                font-size: 0.24rem;
                span{
                    margin-right: 0.56rem;
                    margin-left: 0.26rem;
                }
            }
            dl,dd{
                margin: 0;
            }
            dd{
                position: relative;
                margin-bottom: 0.4rem;
                .info{
                    width: 2.41rem;
                    background-color:  #3C3C64;
                    border-radius: 0.2rem;
                    padding: 0 0.1rem;
                    margin-bottom: 0.1rem;
                    box-sizing: border-box;
                    height: 0.4rem;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    div{
                        display: flex;
                        align-items: center;
                    }
                    img{
                        width: 0.32rem;
                        height: 0.32rem;
                        margin-right: 0.1rem;
                    }
                }
                /*&::after{
                    content: 'OR';
                    position: absolute;
                    width: 100%;
                    text-align: center;
                    top: 0.48rem;
                    left: 0;
                }*/
                &:last-child{
                    margin-bottom: 0.2rem;
                    &::after{
                        display: none;
                    }
                }
            }
            p{
                color: #C8C8C8;
                width: 2.4rem;
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin: 0;
                margin-top: 0.15rem;
            }
        }
    }
}
</style>